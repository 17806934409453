import './App.css'
import React, { useEffect, useState, useRef, } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import bannerImg from '../src/assets/img/1.jpg'
import banner1 from '../src/assets/img/banner1.jpg'
import banner2 from '../src/assets/img/banner2.jpg'
// import serviceImg1 from '../src/assets/img/2.jpg'
// import serviceImg2 from '../src/assets/img/3.jpg'
// import serviceImg3 from '../src/assets/img/4.png'
import qrCode from '../src/assets/img/qr.jpg'
// import custom from '../src/assets/img/custom.png'
// import left1 from '../src/assets/img/left1.png'
// import left2 from '../src/assets/img/left2.png'
// import left3 from '../src/assets/img/left3.png'
// import right1 from '../src/assets/img/right1.png'
// import right2 from '../src/assets/img/right2.png'
// import center from '../src/assets/img/center.jpg'
import wechat from '../src/assets/img/wechat.png'
import with1 from '../src/assets/img/with1.jpg'
import with2 from '../src/assets/img/with2.jpg'
import with3 from '../src/assets/img/with3.jpg'
import with4 from '../src/assets/img/with4.jpg'
import with5 from '../src/assets/img/with5.jpg'
import with6 from '../src/assets/img/with6.jpg'
import with7 from '../src/assets/img/with7.jpg'
import with8 from '../src/assets/img/with8.jpg'
import with9 from '../src/assets/img/with9.jpg'
import with10 from '../src/assets/img/with10.jpg'
import youShang from '../src/assets/img/youshang.png'
import logo from '../src/assets/img/logo.png'
import bgDemo from '../src/assets/img/bgdemo.png'
import serviceIcon1 from '../src/assets/img/serviceIcon1.png'
import serviceIcon2 from '../src/assets/img/serviceIcon2.png'
import serviceIcon3 from '../src/assets/img/serviceIcon3.png'
import serviceImg1 from '../src/assets/img/serviceImg1.jpg'
import serviceImg2 from '../src/assets/img/serviceImg2.jpg'
import serviceImg3 from '../src/assets/img/serviceImg3.jpg'
import subject1 from '../src/assets/img/subject1.jpg'
import subject2 from '../src/assets/img/subject2.jpg'
import subject3 from '../src/assets/img/subject3.jpg'
import subject4 from '../src/assets/img/subject4.jpg'
import subject5 from '../src/assets/img/subject5.jpg'
import reasonImg from '../src/assets/img/reasonImg.jpg'
import serviceBg from '../src/assets/img/serviceBg.jpg'
import serviceBgImg1 from '../src/assets/img/serviceBgImg1.png'
import serviceBgImg2 from '../src/assets/img/serviceBgImg2.png'
import serviceBgImg3 from '../src/assets/img/serviceBgImg3.png'


const services = [
  {
    iconSrc: serviceIcon1,
    imgSrc: serviceImg1,
    bgImgSrc: serviceBgImg1,
    title: '学术翻译',
    content: '专业翻译为您克服语言障碍,助力国际SCI发表',
    points: [
      '覆盖5大核心学科、1373+细分领域的2569+国际学者团队',
      '严格质量控制体系，学术翻译98.78%客户满意率',
      '先翻译再润色确保质量，提升发表概率',
    ]
  },
  {
    iconSrc: serviceIcon2,
    imgSrc: serviceImg2,
    bgImgSrc: serviceBgImg2,
    title: 'SCI全程协助',
    content: '提供论文从评估到发表的全程服务,为您免去后顾之忧',
    points: [
      '与众多出版社和期刊保持紧密合作关系，行业资源广泛',
      '丰富审稿、投稿经验，严控稿件质量，降低退稿风险',
      '深入分析审稿报告，明确您的修改方向，审查和优化返修稿和回复信',
    ]
  },
  {
    iconSrc: serviceIcon3,
    imgSrc: serviceImg3,
    bgImgSrc: serviceBgImg3,
    title: '英文润色',
    content: '全维度提升您的语言表达质量,大幅度提升发表概率',
    points: [
      '来自各学科领域的世界顶尖资深母语专家',
      '编辑周期快，返稿安全快捷',
      '不限次数英文润色，直到你满意为止',
    ]
  },
]


const reasons = [
  {
    title: '全学科服务',
    content: '适用不同类别科研工作者的各项需求，无论您是理工农医还是人文社科，不管您是硕博小白还是科研高手，都可提供全方面的科研论文、课题基金、学术出版服务，为您的学术提升保驾护航。',
  },
  {
    title: '一站式科研学术服务',
    content: '提供职称晋升、评审、加分、评奖等各类论文服务，覆盖行业包括地球科学、生命科学、经济管理、人文社科等，期刊论文、硕博论文、职称论文、毕业论文等英文在线润色和指导服务！',
  },
  {
    title: '提供全程协助服务，助力文稿录用',
    content: '知名期刊审稿人协助论文评估、修改、提升、投稿，全流程协助文章修改、投稿与发表。',
  },
  {
    title: '智能创新提升发表体验',
    content: '我们的全球研发中心在技术升级上步履不停，从数百万稿件的润色经验中总结优化，只为提供更高效、更智能、更贴近您的服务支持。',
  },
]


const subjects = [
  { title: '医疗/药学', imgSrc: subject1, subjectNum: 60, docNum: 70, caseNum: 530 },
  { title: '物理/化学/工程学', imgSrc: subject2, subjectNum: 30, docNum: 80, caseNum: 620 },
  { title: '生命科学', imgSrc: subject3, subjectNum: 50, docNum: 90, caseNum: 410 },
  { title: '人文社科', imgSrc: subject4, subjectNum: 30, docNum: 80, caseNum: 560 },
  { title: '经济管理', imgSrc: subject5, subjectNum: 20, docNum: 60, caseNum: 480 },
]


// const ServiceItem = ({ imgSrc, title, content }) => (
//   <div className="serviceItem">
//     <div className="serviceItemTop">
//       <img className="serviceItemImg" src={imgSrc} alt={title} />
//       <h3 className="serviceItemTitle">{title}</h3>
//     </div>
//     <p className="serviceItemContent">{content}</p>
//   </div>
// )




const Banner = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }

  const BannerItem = ({ imgSrc, title, content, buttonImg, qrCode, type }) => (
    <div className="banner">
      <img className="bannerImg" src={imgSrc} alt="Banner" />
      <div className={`bannerTitle ${type == '2' ? 'extraClass' : ''}`}>
        {title.split(',').map((line, index) => (
          <span key={index}>{line.trim()}<br /></span>
        ))}
      </div>


      {type == "1" && <div className="bannerContent">{content}</div>}
      {type == "2" && <div className="bannerContentType2">{content}</div>}
      {type == '1' && <div className='bannerContainer'>
        <div className="bannerButton">
          <div className="bannerButtonText">立即咨询</div>
          <div className='bannerButtonImgDiv'>
            <img className="bannerButtonImg" src={buttonImg} alt='箭头' />
          </div>
        </div>
        <img className="bannerQrCode" src={qrCode} alt='qrcode' />
      </div>}
    </div>
  )

  return (
    // <Slider {...settings}>
    <div>
      <BannerItem
        imgSrc={banner1}
        title="ADD-您身边的专业学术顾问"
        content="为广大学者提供翻译、润色、论文发表等多方位服务，帮助世界各地的科研人员消除交流障碍，更好地促进知识共享，为学术成果的传播、科学技术的发展服务，助推中国学术高质量发展。平台拥有覆盖1373门学科的2569位国际顶尖学者，提供个性化的定制服务，保证准时返稿，给您贴心完善的售后保障。"
        buttonImg={youShang}
        qrCode={qrCode}
        type="1"
      />
    </div>
    /* <BannerItem
      imgSrc={banner2}
      title="重磅推出SCI全程协助服务,助您发表无忧"
      content="Add同领域期刊审稿人会全面检查研究内容，深入检查论文质量，清楚呈现研究内容、组织架构、证据支持结论和文献资料充足等，并给出建议修订范围。大大提高作者的投稿效率及接收概率。"
      buttonImg={youShang}
      qrCode={qrCode}
      type="2"
    /> */
    // </Slider>
  )
}

const Service = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [isClicked, setIsClicked] = useState(false)
  const sliderRef = useRef(null)

  const handleButtonClick = (index) => {
    if (isClicked) return
    setIsClicked(true)
    setCurrentSlide(index)
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(index)
    }
    setTimeout(() => {
      setIsClicked(false)
    }, 300)
  }

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => setCurrentSlide(current),
  }

  return (
    <div className="service">
      <img src={serviceBg} alt='bg' className='serviceBg'></img>
      <div className="serviceTitle">我们的服务</div>
      <div className="serviceButtonsList">
        {services.map((service, index) => (
          <div
            key={index}
            onClick={() => handleButtonClick(index)}
            className={`serviceButton ${currentSlide === index ? 'serviceButtonActive' : ''}`}
          >
            <img src={service.iconSrc} className='serviceButtonIcon'></img>
            <div>
              {service.title}
            </div>
          </div>
        ))}
      </div>
      <div className="serviceCarousel">
        <Slider className="serviceSlider" ref={sliderRef} {...sliderSettings}>
          {services.map((service, index) => (
            <div className="serviceSliderItem" key={index}>
              <img className="serviceSliderItemImg" src={service.bgImgSrc} alt={service.title} />
              <div className="serviceSliderItemContent">
                <div>
                  <div className="serviceSliderItemDescription"> {service.content.split(',').map((text, idx) => (
                    <div key={idx}>{text}</div>
                  ))}</div>
                  <div className="serviceSliderItemPoints">
                    {service.points.map((point, pointIndex) => (
                      <div key={pointIndex} className="serviceSliderItemPoint">{point}</div>
                    ))}
                  </div>
                </div>
                {/* <div>
                  <img className="serviceSliderItemSmallImg" src={service.imgSrc} alt={service.title} />
                </div> */}
              </div>
            </div>
          ))}
        </Slider>
      </div>
      {/* <div className="serviceList">
        {services.map((service, index) => (
          <ServiceItem key={index} {...service} />
        ))}
      </div> */}
    </div >
  )
}


const Popular = () => {
  const images = [with1, with2, with3, with4, with5, with6, with7, with8, with9, with10]

  return (
    <div className="popular">
      <div className="popularTitle">ADD深受科学出版领域知名出版商的信赖</div>
      <div className="popularSmallTitle">经过我们润色的文章经常出现在以下期刊</div>
      <div className='popularImgList'>
        {images.map((src, index) => (
          <img key={index} className='popularImgItem' src={src} alt='popular' />
        ))}
      </div>
    </div>
  )
}

const Contact = () => (
  <div className="contact">
    <div className='contactTop'>
      <img className="contactLogo" src={logo} alt='logo'></img>
      <div className="contactTitle">地学家</div>
    </div>
    <div className='contactBottom'>
      <div className='contactBottomLeft'>
        Add专注为科研工作者提供英文润色、学术翻译、查重降重、论文发表支持等服务。Add具有严格的质量控制流程，以质量赢得信誉。拥有3000多名具有专业学科背景的资深英文母语编辑、各行各业的学者和期刊编辑专家团队，为近十万名科研工作者提供优质服务。
      </div>
      <div className='contactBottomCenter'>
        <div className="contactUsTitle">联系我们</div>
        <div className="contactFunc">服务热线：19883827653</div>
        <div className="contactFunc">邮箱地址：xiaodan@magicomes.com</div>
      </div>
      <div className='contactBottomRight'>

      </div>
    </div>
  </div>
)

const Float = () => (
  <div className="float-container">
    <img className="floatqrcode" src={qrCode} alt='qrcode'></img>
    <div className='float'>
      <img className="floatImg" src={wechat} alt='qrcode'></img>
      <div>立即咨询</div>
    </div>
  </div>
)

const InfoItem = ({ number, text }) => {
  const [numericPart, symbol] = number.split(/(\D+)/).filter(Boolean)

  return (
    <div className='infoItem'>
      <div className='infoItemNum'>
        <span className='numericPart'>{numericPart}</span>
        <span className='symbolPart'>{symbol}</span>
      </div>
      <div className='infoItemText'>{text}</div>
    </div>
  )
}

const InfoSection = () => (

  <div className='info'>
    <InfoItem number="1300+" text="细分领域" />
    <div className='infoBorder'></div>
    <InfoItem number="3000+" text="教授级专家编辑" />
    <div className='infoBorder'></div>
    <InfoItem number="365天" text="高效服务不打烊" />
    <div className='infoBorder'></div>
    <InfoItem number="25+类服务" text="陪伴写作投稿每一步" />
  </div>

)

// const ReasonItem = ({ imgSrc, title, content, isRight }) => {
//   return (
//     <div className={`reasonItem ${isRight ? 'reasonItemRight' : ''}`}>
//       <img className='reasonItemImg' src={imgSrc} alt={title}></img>
//       <div className='reasonItemTitle'>{title}</div>
//       <div className='reasonItemContent'>{content}</div>
//     </div>
//   )
// }



const Reason = () => {

  const reasonItem = (title, content) => {
    return (
      <div className='reasonItem2'>
        <div className='reasonItemTitle2'>
          {/* <img></img> */}
          {title}
        </div>
        <div className='reasonItemContent2'>{content}</div>
      </div>
    )
  }
  return (
    <div className='reason'>
      <div className='reasonTitle'>为什么选择ADD</div>
      <div className='reasonSmallTitle'>Add深耕学术科研领域，专业、覆盖面全的专家团队和优惠、高体验感的学术服务，帮助科研从业者解决学术道路上各种困扰。</div>
      <div className='reasonContent'>
        <img src={reasonImg} className='reasonContentLeftImg'></img>
        <div className='reasonContentRightList'>
          {reasons.map((reason, index) => (
            reasonItem(reason.title, reason.content)
          ))}
        </div>
      </div>


      {/* <div className='reasonList'>
        <div className='reasonListLeft'>
          <ReasonItem imgSrc={left1} title="强大的编辑和质控团队" content="由同领域学科专家对文稿进行英文精修润色，让您的文稿锦上添花。" />
          <ReasonItem imgSrc={left2} title="审稿人与期刊合作" content="与nature，cope，aug等国际知名的出版社合作，为稿件推荐适合的期刊提升命中率。" />
          <ReasonItem imgSrc={left3} title="便捷支付和正规发票." content="支付宝，微信，便捷支付，开具国家税务正规发票." />
        </div>
        <div className='reasonListCenter'>
          <img className='reasonListImg' src={center} alt='reasonListImg'></img>
        </div>
        <div className='reasonListRight'>
          <ReasonItem imgSrc={right1} title="卓越的质量与售后保障" content="3000+编辑团队，平均英文润色经验13年，擅长修改非母语作者的英语论文." isRight />
          <ReasonItem imgSrc={right2} title="多次润色服务" content="华人专家对稿件进行初译，然后进行二轮精修润色，确保专业术语准确，译文不偏离原意。" isRight />
        </div>
      </div> */}
    </div>
  )
}

const Subject = () => {

  const SubjectItem = ({ title, imgSrc, subjectNum, docNum, caseNum }) => {
    return (
      <div className='subjectItem'>
        {/* <div className='subjectItemTop'>
          <img className='subjectItemTopBg' alt='' />
          <img className='subjectItemTopIcon' alt='' />
          <div className='subjectItemTopTitle'>{title}</div>
        </div> */}
        <img src={imgSrc} className='subjectItemBg'></img>
        <div className='subjectItemBottom'>
          <div className='subjectItemBottomNum'>{subjectNum}+</div>
          <div className='subjectItemBottomTitle'>学科</div>
          <div className='subjectItemBottomNum'>{docNum}+</div>
          <div className='subjectItemBottomTitle'>专家、编辑和翻译</div>
          <div className='subjectItemBottomNum'>{caseNum}+</div>
          <div className='subjectItemBottomTitle'>服务案例</div>
        </div>
      </div>
    )
  }

  return (
    <div className='subject'>
      <div className='subjectTitle'>5大核心主学科 1300+个细分领域</div>
      <div className='subjectSmallTitle'>Add专家编辑团队覆盖医学/医药、生命科学、物理/化学/工程学、人文社科、商业经济与管理五大类主学科的1300+门细分学科，更有大量跨学科领域的专家学者。<br></br>意得辑更集结行业专家创立卓越中心，为您的稿件分配最合适的编辑和翻译。</div>
      <div className='subjectList'>
        {subjects.map((subject, index) => (
          <SubjectItem
            key={index}
            title={subject.title}
            imgSrc={subject.imgSrc}
            subjectNum={subject.subjectNum}
            docNum={subject.docNum}
            caseNum={subject.caseNum}
          />
        ))}
      </div>
    </div>
  )
}

function App () {

  useEffect(() => {
    document.title = "地学家"
  }, [])

  return (
    <div className="App">
      {Banner()}
      {InfoSection()}
      {Service()}
      {Reason()}
      {Subject()}
      {Popular()}
      {Contact()}
      {Float()}
    </div>
  )
}

export default App
